
import {
  Component,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'PageNotFound',
})
export default class PageNotFound extends Vue {

}
